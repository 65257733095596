import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  { // staking
    pid: 0,
    rewardRate: 158,
    apy: 50,
    stake: true,
    lpSymbol: 'BAFI',
    lpAddresses: {
      97: '',
      56: '0xA2f46fe221f34Dac4Cf078e6946A7cb4e373AD28',
    },
    token: tokens.bafi,
    quoteToken: tokens.busd,
  },
  {
    pid: 1,
    rewardRate: 630,
    apy: 200,
    lpSymbol: 'BNB-BAFI LP',
    lpAddresses: {
      97: '',
      56: '0x41fb8A3869689628Da6385978377331AB7f02e54',
    },
    token: tokens.bafi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    apy: 150,
    rewardRate: 472,
    lpSymbol: 'BAKE-BAFI LP',
    lpAddresses: {
      97: '',
      56: '0x57978a1628C4A6456a251cDbb3DCDC3A70225638',
    },
    token: tokens.bafi,
    quoteToken: tokens.bake,
  },
  {
    pid: 3,
    rewardRate: 441,
    apy: 140,
    lpSymbol: 'CAKE-BAFI LP',
    lpAddresses: {
      97: '',
      56: '0x2236edc12d83C484586E603AfB7Cb3cD7Ada1977',
    },
    token: tokens.bafi,
    quoteToken: tokens.cake,
  },
  {
    pid: 4,
    rewardRate: 315,
    apy: 100,
    lpSymbol: 'BUSD-BAFI LP',
    lpAddresses: {
      97: '',
      56: '0x09e1EDf42Be5d72286AB87276044E318B68dD596',
    },
    token: tokens.bafi,
    quoteToken: tokens.busd,
  },
  {
    pid: 5,
    apy: 50,
    rewardRate: 158,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x48F86F87BDfBCC626565641e3f5787347196182B',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 6,
    apy: 200,
    rewardRate: 630,
    lpSymbol: 'BBSC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x3f322a93FB29B1aD581bcE3AaB4D615f03fd64b6',
    },
    token: tokens.bbsc,
    quoteToken: tokens.busd,
  },
]

export default farms
