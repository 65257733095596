import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 75,
    stakingToken: tokens.bafi,
    earningToken: tokens.bafi,
    apy: 121.75,
    contractAddress: {
      97: '',
      56: '0xB4C68A1C565298834360BbFF1652284275120D47',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00007234',
    sortOrder: 999,
    isFinished: true,
  },
]

export default pools
