export default {}

export const appConfig = {
  name: 'Bafiswap',
  ticker: 'BAFI',
  title: 'The AMM that is better than the rest',
  logo: '',
  description: '',
  website: 'https://bafi.finance',
  exchange: 'https://swap.bafi.finance',
}

export const defaultToken = {
  symbol: 'BAFI',
  address: {
    56: '0xA2f46fe221f34Dac4Cf078e6946A7cb4e373AD28',
    97: '',
  },
  decimals: 18,
  projectLink: 'https://bafi.finance/',
}

export const quickLinks = [
  {
    label: 'Bafi Swap',
    href: 'https://swap.bafi.finance'
  },
  {
    href: 'https://swap.bafi.finance/#/swap',
    label: 'Buy BAFI'
  },
  {
    label: 'Provide Liquidity',
    href: 'https://swap.bafi.finance/#pool'
  },
  {
    label: 'Blog',
    href: 'https://bafifinance.medium.com/'
  },
  {
    label: 'Github',
    href: 'https://github.com/Bazdev2050'
  },
  {
    label: 'Twitter',
    href: 'https://twitter.com/Bafifinancee'
  },
  {
    label: 'Telegram',
    href: 'https://t.me/Bafifinance'
  },
]
