import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://bafi.finance/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: 'https://swap.bafi.finance/#swap',
      },
      {
        label: 'Liquidity',
        href: 'https://swap.bafi.finance/#pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Ido',
    icon: 'IfoIcon',
    href: '/ido',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: "Twitter",
        href: "https://twitter.com/Bafifinancee"
      },
      {
        label: "Telegram",
        href: "https://t.me/Bafifinance"
      },
      {
        label: 'Blog',
        href: 'https://medium.com/@bafifinance',
      },
    ],
  },
]

export default config
